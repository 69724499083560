<template>
    <r-e-dialog title="水电详情" :visible.sync="detailsDialogVisible" show-footer top="10vh"
                @click-submit="detailsDialogVisible = false" @click-cancel="detailsDialogVisible = false"
                @close="detailsDialogVisible = false">
        <r-e-table ref="weterElereTableRef" :dataRequest="getWeterElereList" :columns="waterElectricityDetails" notAuto
                   show-summary :summary-method="getSummaries" :afterDataReq="hydropowertotal" :row-style="rowStyle">
            <!-- 2024/03/26 张晓瑜隐藏操作按钮 -->
            <!-- <el-table-column slot="toolbar" label="操作" width="150">
                <templa/>te slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.waterDataDevice !== 0 && row.electDataDevice !== 0 && row.updateStatus === 0" @click="edit(row)">编辑</span>
                    </div>
                </template>
            </el-table-column> -->
        </r-e-table>
        <layer-daily-usage-update ref="layerDailyUsageUpdate" @handleSearch="handleSearch" />
    </r-e-dialog>
</template>

<script>
import {dailyUsage} from "@/api/tenant-management";
import {waterElectricityDetails,waterElectricityDetailsson} from "@/views/rental-management/tenant-management/data";
import {numberFormat} from "@custom";

export default {
    name: "layer-water-electricity-details",
    data() {
        return {
          apartmentIsChild:null,
            // row
            detailsDialogVisible: false,
            waterElectricityDetails:waterElectricityDetails(this),
            balanceUuid: null,
        };
    },
    components: {
        layerDailyUsageUpdate: () => import("./layer-daily-usage-update"),
    },
    methods: {
             apartmentIsChildvalue(){
               return this.apartmentIsChild
             },
        async openDialog(data) {
            let that = this;
            const {uuid,apartmentIsChild} = data;
            that.apartmentIsChild = apartmentIsChild
            // alert(this.apartmentIsChild)
          // 2024-1-19-暂时隐藏水电
          if(apartmentIsChild)  that.waterElectricityDetails = waterElectricityDetailsson()
            that.balanceUuid = uuid;
            that.detailsDialogVisible = true;
            that.$nextTick(() => {
                that.$refs["weterElereTableRef"].getTableData(true);
            });
        },

        getWeterElereList(params) {
            params.balanceUuid = this.balanceUuid;
            return dailyUsage(params);
        },

        getSummaries(param) {
            const {columns, data} = param;
            console.log('param',param);
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 张小鱼-水电修改-计算错误
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    console.log('sums[index]',sums[index]);
                    console.log('values',values);
                    sums[index] = values.reduce((prev, curr) => {
    const value = Number(curr);
    if (!isNaN(value)) return prev + value; // 累加每个有效值
    else return prev;
}, 0); // 在求和结果后除以 100
                    if (index === 4 ) {
                        sums[index] = numberFormat(sums[index]/100 );
                    } 
                    if (index === 3 ) {
                        sums[index] = '/'; // 在列索引为3的位置显示斜杠字符
                    }else {
                        sums[index] = numberFormat(sums[index]);
                    }
                } else sums[index] = '';

            });
            return sums;
        },
        hydropowertotal(param) {
            // 在这里处理数据，新增处理后的字段
            return param.map(item => {
                item.waterCharge = (item.currentWater - item.lastWater).toFixed(2);
                item.eleCharge = (item.currentElect - item.lastElect).toFixed(2);
                return item;
            });
        },
        rowStyle(row) {
            const {row: {updateStatus, waterDataDevice, electDataDevice}} = row;
            if (waterDataDevice === 0 && electDataDevice === 0) return {}
            if (updateStatus === 0) return {backgroundColor: 'rgba(255,69,0,0.2)'};
            if (updateStatus === 1) return {backgroundColor: 'rgba(30,144,255,0.2)'};
        },

        handleSearch(isSearch = true) {
            // 搜索
            this.$refs["weterElereTableRef"].getTableData(isSearch);
        },
        edit(data){
            this.$refs["layerDailyUsageUpdate"].openDialog(data);
        }
    }
}
</script>

<style scoped>

</style>